function getExtensioIn(file) {
  let extension = "";
  if (file.name) {
    extension =
      file.name.lastIndexOf(".") !== -1
        ? file.name.substr(file.name.lastIndexOf(".") + 1)
        : "";
  }
  return extension;
}

function init() {
  const loader = document.getElementById("loader");
  const overlay = document.getElementById("overlay");
  const overlayConversion =
    overlay.getElementsByClassName("overlayConversion")[0];
  const overlayOptimization = overlay.getElementsByClassName(
    "overlayOptimization"
  )[0];
  const overlaySecurisation = overlay.getElementsByClassName(
    "overlaySecurisation"
  )[0];
  const overlayConversionSuccess = overlay.getElementsByClassName(
    "overlayConversionSuccess"
  )[0];
  const overlayOptimizationSuccess = overlay.getElementsByClassName(
    "overlayOptimizationSuccess"
  )[0];
  const overlayProgress = overlay.getElementsByClassName(
    "loader__circle__progress"
  )[0];
  const formUploader = document.getElementsByName("uploader")[0];
  const email = $("#uploader_email");
  let submitButton = $("#convert");
  if (submitButton.length === 0) {
    submitButton = $("#optimization");
  }
  let downloadButton = $("#download");

  let isRequestFinished = false;
  let isOptimization = !!window.location.href.match(/\/optim/);
  let dropZoneArea = document.getElementById("dropzoneArea");
  let myDropzone;

  if (dropZoneArea) {
    let timerIds = [];
    let startFakeProgress = function (file) {
      // Returns a random integer from 0 to 14:
      let percent = Math.floor(Math.random() * 15);
      let timeout = Math.floor(Math.random() * 500) + 200;
      let timerId = setInterval(function () {
        percent += 10;
        myDropzone.emit(
          "uploadprogressfake",
          file,
          percent,
          file.upload.bytesSent
        );
        if (percent >= 90) {
          clearInterval(timerId);
          setTimeout(function () {
            let progressElement =
              file.previewElement.querySelector(".dz-upload");
            if ($(progressElement).length) {
              progressElement.remove();
              file.previewElement
                .querySelector(".dz-progress")
                .classList.add("completed");
              file.previewElement.classList.add("dz-complete-fake");
            }
            // Enable submit
            submitButton.prop("disabled", false);
            timerIds.pop();
          }, timeout);
        }
      }, timeout);
      timerIds.push(timerId);
    };

    let unauthorizedLabel = Translator.trans("unauthorizedLabel");
    let fileTooBigLabel = Translator.trans("fileTooBigLabel");
    let compressingLabel = Translator.trans("compressingLabel");
    let maxfilesexceeded = false;
    let url = "/multiple-upload";
    myDropzone = new Dropzone("div#dropzoneArea", {
      url: url,
      createImageThumbnails: false,
      previewTemplate: document.querySelector("#upload-files").innerHTML,
      dictInvalidFileType: unauthorizedLabel,
      dictFileTooBig: fileTooBigLabel,
      maxFiles: 10,
      parallelUploads: 10,
      maxFilesize: 1000, // 1Gb
      uploadMultiple: false,
      autoProcessQueue: true,
      forceFallback: false, // This is very useful to test your server implementations first and make sure that everything works as expected without dropzone
      chunking: true, // enable chunking
      forceChunking: true, // forces chunking when file.size < chunkSize
      retryChunks: true, // retry chunks on failure
      retryChunksLimit: 3, // retry maximum of 3 times (default is 3)
      init: function () {
        this.on("addedfile", function (file) {
          // Disable submit
          submitButton.prop("disabled", true);

          let countFiles = myDropzone.files.length;
          let previewElement = $(file.previewElement);

          $(".uploader__errors__accountalreadyexists").hide();

          previewElement.attr("id", "dropzone_" + countFiles);
          $(".uploader__submit")
            .addClass("visible")
            .insertAfter(previewElement);

          let paddingTop = 300 + countFiles * 70;
          $(".callout.counter").css("padding-top", paddingTop + "px");

          if (countFiles <= this.options.maxFiles) {
            if (!userLogged) {
              startFakeProgress(file);
            }
            if (!isOptimization) {
              // File select dropdown
              previewElement
                .find("input.extensionOut")
                .val(file.upload.uuid + "_");
              previewElement
                .find(".file-type-selection ul li")
                .click(fileTypeSelection);
              if (isLanding) {
                previewElement.find(".file-type-selection ul li:first").click();
              }
              // TODO delay not working
              previewElement
                .find("button")
                .focus()
                .delay(500)
                .blur()
                .delay(500)
                .focus();
            }
            if (isOptimization) {
              let extension = getExtensioIn(file);
              if (
                ["jpg", "jpeg", "png", "gif", "bmp", "tiff"].includes(extension)
              ) {
                let optimizedInfos = file.previewElement.querySelector(
                  ".dz-optimized-infos"
                );
                optimizedInfos.querySelector(".loading").style.display =
                  "block";
                optimizedInfos.querySelector(".infos").textContent =
                  compressingLabel;
                optimizedInfos.classList.add("visible");
              }
            }
            setTimeout(function () {
              email.focus();
              if (!userLogged) {
                // Enable submit
                submitButton.prop("disabled", false);
              }
            }, 1000);
            $(window).scrollTop(paddingTop);
          }

          dropZoneArea.classList.add("more-files");
        });
        this.on("uploadprogressfake", function (file, progress, bytesSent) {
          if (file.previewElement) {
            for (let node of file.previewElement.querySelectorAll(
              "[data-dz-uploadprogress]"
            )) {
              node.nodeName === "PROGRESS"
                ? (node.value = progress)
                : (node.style.width = `${progress}%`);
            }
          }
        });
        this.on("complete", function (file) {
          if (userLogged) {
            file.previewElement.classList.add("dz-complete-fake");
          }
        });
        this.on("maxfilesexceeded", function (file) {
          this.removeFile(file);
          maxfilesexceeded = true;
        });
        this.on("queuecomplete", function (files, response) {
          if (maxfilesexceeded) {
            $("#dropzoneArea .dropzone").css("top", "530px");
            $("#dropzoneArea .uploader__submit").css("top", "530px");
            $(window).scrollTop($(".uploader").offset().top);
            $(".uploader__errors__maxfilesexceeded")
              .css("display", "table-cell")
              .fadeIn()
              .delay(2000)
              .fadeOut();
            maxfilesexceeded = false;
            setTimeout(function () {
              $("#dropzoneArea .dropzone").css("top", "250px");
              $("#dropzoneArea .uploader__submit").css("top", "250px");
            }, 3000);
          }
          // Enable submit
          submitButton.prop("disabled", false);
        });
        this.on("removedfile", function (file) {
          let currentFile = file;
          let dzuuid = currentFile.upload.uuid ?? "";
          let extension = getExtensioIn(currentFile);

          // This calls server-side code to remove file from session
          $.ajax({
            url: "/remove-file?dzuuid=" + dzuuid + "&extension=" + extension,
            dataType: "json",
            error: function (data) {
              console.log("error");
              console.log(data);
            },
          });

          if (myDropzone.files.length === 0) {
            dropZoneArea.classList.remove("more-files");
            $(".uploader__submit").removeClass("visible");
          }
        });
      },
      // event handler Overriding ⚠️
      chunksUploaded: function (file, done) {
        // All chunks have been uploaded. Perform any other actions
        let currentFile = file;
        let dzuuid = currentFile.upload.uuid ?? "";
        let dztotalchunkcount = currentFile.upload.totalChunkCount ?? "";
        let extension = "";
        if (currentFile.name) {
          extension =
            currentFile.name.lastIndexOf(".") !== -1
              ? currentFile.name.substr(currentFile.name.lastIndexOf(".") + 1)
              : "";
        }

        // This calls server-side code to merge all chunks for the currentFile
        $.ajax({
          url:
            "/chunk-concat?dzuuid=" +
            dzuuid +
            "&dztotalchunkcount=" +
            dztotalchunkcount +
            "&extension=" +
            extension +
            "&isOptimization=" +
            isOptimization,
          dataType: "json",
          success: function (data) {
            if (isOptimization) {
              let progressElement =
                file.previewElement.querySelector(".dz-upload");
              if ($(progressElement).length) {
                progressElement.remove();
                file.previewElement
                  .querySelector(".dz-progress")
                  .classList.add("completed");
              }
              let optimizedInfos = file.previewElement.querySelector(
                ".dz-optimized-infos"
              );
              if (data.originalSize) {
                optimizedInfos.querySelector(".infos").textContent =
                  data.originalSize +
                  " → " +
                  data.lossySize +
                  " | " +
                  data.percentImprovement;
                optimizedInfos.classList.add("visible");
                optimizedInfos.querySelector(".loading").remove();
                submitButton.hide();
                downloadButton.show();
              } else {
                optimizedInfos.style.display = "none";
              }
            }
            done();
            myDropzone.options.autoProcessQueue = true;
          },
          error: function (data) {
            console.log("error");
            console.log(data);
            currentFile.accepted = false;
            if (currentFile.previewElement) {
              currentFile.previewElement.classList.add("dz-error");
              currentFile.previewElement.querySelectorAll(
                ".dz-error-message span"
              )[0].textContent = data.responseJSON;
            }
          },
        });
      },
      // event handler Overriding ⚠️
      uploadprogress: function (file, progress, bytesSent) {
        if (userLogged && file.previewElement) {
          for (let node of file.previewElement.querySelectorAll(
            "[data-dz-uploadprogress]"
          )) {
            node.nodeName === "PROGRESS"
              ? (node.value = progress)
              : (node.style.width = `${progress}%`);
          }
          if (progress === 100) {
            let progressElement =
              file.previewElement.querySelector(".dz-upload");
            if ($(progressElement).length) {
              progressElement.remove();
              file.previewElement
                .querySelector(".dz-progress")
                .classList.add("completed");
            }
          }
        }
      },
      // event handler Overriding ⚠️
      error: function (file, message) {
        console.log("ERROR");
        console.log(message);

        if (file.previewElement) {
          file.previewElement.classList.add("dz-error");
          if (typeof message !== "string" && message.error) {
            message = message.error;
          }
          for (let node of file.previewElement.querySelectorAll(
            "[data-dz-errormessage]"
          )) {
            node.textContent = message;
          }
        }
      },
    });

    $("#browse, #dropzoneArea div:not(.dropzone)").click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      myDropzone.hiddenFileInput.click();
    });

    let showOverlay = function () {
      if (isOptimization) {
        overlayOptimization.style.display = "block";
      } else {
        overlayConversion.style.display = "block";
      }
      overlayProgress.style.display = "block";
      isRequestFinished = false;
      overlay.classList.add("is-loading");

      let current_progress = 0;
      let step = 0.2;

      let interval = setInterval(function () {
        current_progress += step;
        let progress = Math.round(
          (Math.atan(current_progress) / (Math.PI / 2)) * 100
        );
        overlayProgress.textContent = progress + "%";
        if (progress >= 100) {
          clearInterval(interval);
        } else if (progress >= 70) {
          step = 0.1;
        }
      }, 100);

      setTimeout(function () {
        if (isOptimization) {
          overlayOptimization.style.display = "block";
        } else {
          overlayConversion.style.display = "none";
        }
      }, 2000);

      setTimeout(function () {
        if (isOptimization) {
          overlayOptimization.style.display = "none";
        } else {
          overlayConversion.style.display = "none";
        }
        overlaySecurisation.style.display = "block";
      }, 4000);

      setTimeout(function () {
        clearInterval(interval);
        overlayProgress.textContent = "100%";
        overlaySecurisation.style.display = "none";
        if (isOptimization) {
          overlayOptimizationSuccess.style.display = "block";
        } else {
          overlayConversionSuccess.style.display = "block";
        }
        isRequestFinished = true;
      }, 6000);
    };

    loader.addEventListener("animationiteration", () => {
      if (isRequestFinished) {
        loader.classList.add("is-finishing");
      }
    });

    loader.addEventListener("animationend", () => {
      setTimeout(function () {
        overlay.classList.remove("is-loading");
        loader.classList.remove("is-finishing");
        isRequestFinished = false;

        // Put the files into storage
        // localStorage.setItem('acceptedFiles', JSON.stringify(myDropzone.getAcceptedFiles()));

        formUploader.submit();
        $("div.overlayConversionSuccess").fadeOut("slow");
      }, 1000);
    });

    /**
     * Validaton
     */

    $.fn.toggleValidClass = function (condition) {
      $(this).toggleClass("is-valid", condition);
      $(this).toggleClass("is-invalid", !condition);
    };

    function validEmail() {
      let condition = validateEmail(email.val());
      email.toggleValidClass(condition);
      return condition;
    }

    if (email.length) {
      email.on("keyup change", validEmail);
    }

    let fileTypeSelection = function () {
      let fileType = $(this).attr("class") ? $(this).attr("class") : "";
      let fileText = $(this).html() ? $(this).html() : "";
      let extensionOut = fileType.substring(10);
      let dropdown = $(this).closest(".dropdown");

      // Add text
      dropdown
        .find(".btn-file-type")
        .addClass("btn-file-type--selected")
        .html(fileText);
      dropdown.closest(".dropzone").removeClass("dz-error");

      // hidden value
      let input = dropdown.find("input.extensionOut");
      let value = input.val();
      let pos = value.lastIndexOf("_");
      value = value.substring(0, pos);
      value += "_" + extensionOut;
      input.val(value);
      input.data("isValid", true);

      dropdown.css("border", "inherit");
    };

    // File select dropdown
    $(".file-type-selection ul li").click(fileTypeSelection);

    let submitFunction = function (e) {
      e.preventDefault();
      e.stopPropagation();

      let isValid = true;

      if (!userLogged && timerIds.length > 0) {
        isValid = false;
      }

      if (!isOptimization) {
        let extensiosnOut = $(formUploader).find("input.extensionOut");
        if (extensiosnOut.length === 0) {
          isValid = false;
        } else {
          extensiosnOut.each(function () {
            if ($(this).data("isValid") !== true) {
              let previewElement = $(this).closest(".dropzone");
              $(previewElement).find("button").focus();
              $(previewElement).addClass("dz-error");

              let errorMessage = Translator.trans("fileFormatMissing");
              $(previewElement)
                .find(".dz-error-message span")
                .text(errorMessage);

              isValid = false;
              return false;
            }
          });
        }
      }

      if (!userLogged && isValid && !validEmail()) {
        email.focus();
        isValid = false;
      }

      if (isValid) {
        showOverlay();
      }
    };
    if (submitButton.length > 0) {
      submitButton.click(submitFunction);
    }
    if (downloadButton.length > 0) {
      downloadButton.click(submitFunction);
    }

    // Retrieve the files from storage
    //let acceptedFiles = localStorage.getItem('acceptedFiles');
    // //console.log('acceptedFiles: ', JSON.parse(acceptedFiles));
  }
}

window.addEventListener("pageshow", (ev) => {
  init();
});
